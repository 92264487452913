import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
  }

  close() {
    this.element.remove();
  }
}
