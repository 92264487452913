import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["coordinate", "address", "zipCode", "city"]
  serviceURL = "/geocode?query="

  initialize() {
    // map
  }

  geocode() {
    if (this.fullAddress == '') {
      alert("Please fill in an address first.")
      return false;
    }

    fetch(this.serviceURL + this.fullAddress)
      .then(response => response.json())
      .then(json => {
        if(json.status === "OK") {
          this.coordinate = json.coordinate;
        }
        else {
          alert('No coordinate found for: ' + this.fullAddress);
          this.coordinate = null
        }
      })
      .catch(error => {
        alert('Error fetching coordinate for: ' + this.fullAddress);
        this.coordinate = null
      })
  }

  get coordinate() {
    return this.data.get("coordinate").split(",")
  }

  set coordinate(latLngArr) {
    if (latLngArr == null) return false;

    var reversed = latLngArr.reverse();
    var coordinateStr = reversed.join(', ');
    this.data.set("coordinate", coordinateStr)
    this.coordinateTarget.value = coordinateStr;
    var data = { 'points': [ { 'geometry': [reversed[1], reversed[0]], 'name': 'coordinate', 'path': '#' } ] }
    this.mapController.setMainData(data)
  }

  get fullAddress() {
    var fullAddress = []
    if (this.hasAddressTarget) {
      fullAddress.push(this.addressTarget.value)
    }
    fullAddress.push(this.zipCodeTarget.value)
    fullAddress.push(this.cityTarget.value)

    return fullAddress.filter(value => value !== '').join(', ');
  }

  get mapController() {
    return this.application.getControllerForElementAndIdentifier(this.element.querySelector("[data-controller='map']"), "map")
  }
}
