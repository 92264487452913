import { Controller } from "stimulus"
import Glide from "@glidejs/glide"

export default class extends Controller {

  connect() {
    new Glide(this.element, {
      rewind: true
    }).mount();
  }
}
