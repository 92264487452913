// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("@glidejs/glide");
require("lodash");
require("flatpickr");

import "jquery";
import "bootstrap"
import "popper.js"
import "select2"
import "select2/dist/js/i18n/nl"
import "simplelightbox/dist/simple-lightbox.jquery"

import "css/application.scss";

// Which will recursively load all modules within the current folder that end in .js.
var requireApplication = require.context('../application', true, /\.js$/);
requireApplication.keys().forEach(requireApplication);

window.onpopstate = function(event) {
  if (event.state) {
    window.location = event.target.location;
  }
};

$.fn.select2.defaults.set("theme", "bootstrap");

// Initialize Stimulus and load controllers
const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))
