import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    // If `FB` object is available, call parse to initialize all FB feeds
    // If not available, add Facebook JS to page. After loading that JS will initialize the FB feeds automatically
    if (typeof(FB) == 'undefined') {
      var facebook_js = document.createElement('script');
      facebook_js.src = "https://connect.facebook.net/nl_NL/sdk.js#xfbml=1&version=v4.0";
      facebook_js.crossorigin = "anonymous";
      document.body.appendChild(facebook_js);
    } else {
      FB.XFBML.parse()
    }
  }

  disconnect() {
  }
}
