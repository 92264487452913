const fetchHeaders = () => {
  return {
    Accept: "application/javascript",
    "Content-Type": "application/json",
    "X-CSRF-Token": csrfToken(),
    "X-Requested-With": "XMLHttpRequest",
  };
};

const csrfToken = () => {
  var token = document.querySelector("meta[name=csrf-token]");
  return !!token ? token.content : "";
}

export { fetchHeaders };
