import { Controller } from "stimulus";
import { fetchHeaders } from "../helpers/fetch_helper";

// import Glide from "@glidejs/glide";
import Feature from "ol/Feature";
import { Map, View } from "ol";
import * as olExtent from "ol/extent";
import { Point, LineString } from "ol/geom";
import { fromLonLat, transformExtent, get as projGet } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Cluster from "ol/source/Cluster";
import TileJSON from "ol/source/TileJSON";
import Select from "ol/interaction/Select";
import KML from "ol/format/KML";
import { defaults as defaultControls, FullScreen } from "ol/control";
import { Circle as CircleStyle, Fill, Stroke, Icon, Style, Text } from "ol/style.js";
import Overlay from "ol/Overlay";
import { defaults as defaultInteractions, DragPan, MouseWheelZoom } from "ol/interaction";
import { platformModifierKeyOnly } from "ol/events/condition";

export default class extends Controller {
  static targets = ["map", "popup", "popupContent", "hover"];
  hasTempContent = false;

  connect() {
    this.initMap();
    this.setMainData(JSON.parse(this.data.get("data")));
    APP[this.data.get("name") + "Map"] = this;
    this.addEventListeners();
  }

  disconnect() {
    delete APP[this.data.get("name") + "Map"];
    this.map.setTarget(null);
    delete this.map;
    this.removeEventListeners();
  }

  initMap() {
    let controls = defaultControls();
    if (this.data.has("allow-full-screen")) {
      controls.extend([new FullScreen()]);
    }
    let interactions = defaultInteractions();
    if (this.data.has("disable-pan-and-zoom")) {
      interactions = defaultInteractions({ dragPan: false, mouseWheelZoom: false }).extend([
        new DragPan({
          condition: function (event) {
            return this.getPointerCount() === 2 || platformModifierKeyOnly(event);
          },
        }),
        new MouseWheelZoom({
          condition: platformModifierKeyOnly,
        }),
      ]);
    }
    this.map = new Map({
      interactions: interactions,
      controls: controls,
      target: this.mapTarget,
      layers: this.createLayers(),
      view: new View({}),
    });

    this.addFilterableProperties();
    this.setMapPosition();
  }

  setMainData(data) {
    // Write new data to HTML element for persistence, provides the option to reload
    this.data.set("data", JSON.stringify(data));

    if (!this.hasTempContent) {
      this.showPoints(data.points);
      this.showLines(data.lines);
    }
    this.showKmlFiles(data.kml_file_urls);

    if (!!data.delivery_location) {
      this.setDeliveryLocation(data.delivery_location);
    } else if (!!data.user_location) {
      // Try to prevent to overwrite a location that was retrieved by watching user's location
      if (!this.location || !data.allow_location_updates || !this.locationWatchId) {
        this.setUserLocation(data.user_location, data.allow_location_updates);
      }
    }
  }

  showChain(event) {
    var data = JSON.parse(event.target.dataset.mapData);
    this.hasTempContent = true;

    this.showPoints(data.points);
    this.showLines(data.lines);
    this.showTempPlots(data.plots);

    event.preventDefault();
  }

  setUserLocation(coordinates, autoUpdate) {
    this.panMap(coordinates);
    this.location = coordinates;
    this.locationIcon = "/icon_user_location.svg";
    this.showLocation();

    if (autoUpdate) {
      // Enable auto-update, unless previously enabled
      if (this.locationWatchId == null) {
        this.locationWatchId = navigator.geolocation.watchPosition((position) => {
          this.setUserLocation([position.coords.longitude, position.coords.latitude], autoUpdate);
        });
      }
    } else {
      // Disable auto-update, if previously enabled
      if (this.locationWatchId != null) {
        navigator.geolocation.clearWatch(this.locationWatchId);
        this.locationWatchId = null;
      }
    }
  }

  setDeliveryLocation(coordinates) {
    this.location = coordinates;
    this.locationIcon = "/icon_delivery_location.svg";
    this.showLocation();

    // Disable auto-update, if previously enabled
    if (this.locationWatchId != null) {
      navigator.geolocation.clearWatch(this.locationWatchId);
      this.locationWatchId = null;
    }
  }

  showPoints(points) {
    this.pointsSource.clear(true);
    this.premiumSource.clear(true);

    if (typeof points == "undefined" || points === null) {
      return;
    }

    let pointFeatures = [];
    let premiumFeatures = [];
    for (var i = 0; i < points.length; i++) {
      points[i].geometry = new Point(fromLonLat(points[i].geometry));
      if (points[i].premium) {
        premiumFeatures.push(new Feature(points[i]));
      } else {
        pointFeatures.push(new Feature(points[i]));
      }
    }

    this.pointsSource.addFeatures(pointFeatures);
    this.premiumSource.addFeatures(premiumFeatures);

    this.centerMap();
  }

  showLines(lines) {
    this.linesSource.clear(true);

    if (typeof lines == "undefined" || lines === null) {
      return;
    }

    let lineFeatures = [];

    for (var i = 0; i < lines.length; i++) {
      lines[i].geometry = new LineString([fromLonLat(lines[i].geometry[0]), fromLonLat(lines[i].geometry[1])]);
      lineFeatures.push(new Feature(lines[i]));
    }

    this.linesSource.addFeatures(lineFeatures);
  }

  showKmlFiles(kmlFiles) {
    if (typeof kmlFiles == "undefined" || kmlFiles === null) {
      return;
    }

    this.kmlSource.clear(true);
    for (var i = 0; i < kmlFiles.length; i++) {
      this.addKmlSource(kmlFiles[i], this.kmlSource);
    }
  }

  createLayers() {
    let layers = [
      new TileLayer({
        source: new TileJSON({
          url: "https://api.maptiler.com/maps/basic/256/tiles.json?key=d1UQgpEC5ioErApN6xuA",
          crossOrigin: "anonymous",
        }),
      }),
    ];

    layers.push(this.createKmlLayer());
    layers.push(this.createPlotsLayer());
    layers.push(this.createLinesLayer());
    layers.push(this.createPointsLayer());
    layers.push(this.createPremiumLayer());
    layers.push(this.createLocationLayer());

    return layers;
  }

  createPointsLayer() {
    this.pointsSource = new VectorSource({});
    var clusterSource = new Cluster({ distance: 12, source: this.pointsSource });
    var zIndex = 0;

    this.pointsLayer = new VectorLayer({
      source: clusterSource,
      style: (cluster) => {
        return this.pointIconStyle(cluster.getProperties().features, zIndex++);
      },
    });
    return this.pointsLayer;
  }

  createPremiumLayer() {
    this.premiumSource = new VectorSource({});
    var clusterSource = new Cluster({ distance: 12, source: this.premiumSource });
    var zIndex = 0;

    this.premiumLayer = new VectorLayer({
      source: clusterSource,
      style: (cluster) => {
        let firstFeature = cluster.getProperties().features[0];
        return this.premiumIconStyle(firstFeature, zIndex++);
      },
    });
    return this.premiumLayer;
  }

  createLinesLayer() {
    this.linesSource = new VectorSource({});

    this.linesLayer = new VectorLayer({ source: this.linesSource, style: this.lineStyle() });
    return this.linesLayer;
  }

  premiumIconStyle(feature, zIndex, selected = false) {
    let iconImage = `/unit_types/${feature.getProperties().icon_type}.svg`;

    return new Style({
      image: new Icon({
        crossOrigin: "anonymous",
        src: iconImage,
        anchor: [0.3, 0.9],
        scale: selected ? 1.5 : 1,
      }),
      zIndex: 1000 + zIndex,
    });
  }

  pointIconStyle(features, zIndex, selected = false) {
    var size = features.length;

    let iconTypeArrays = [];
    features.forEach((f) => {
      iconTypeArrays.push(f.getProperties().icon_type);
    });
    let uniqueIconTypes = _.uniq(_.flatten(iconTypeArrays));

    let iconColors = {
      farm: "#ff0033",
      shop: "#016450",
      restaurant: "#0099cc",
      processor: "#ffa08e",
      recreation: "#017690",
      supportyourlocals: "#eb5f58",
      food_community: "#eb5f58",
    };

    let iconColor = "#757575"; // gray, default. Mostly clusters.
    if (uniqueIconTypes.length == 1) {
      iconColor = iconColors[uniqueIconTypes[0]];
    }

    return new Style({
      image: new CircleStyle({
        radius: selected ? 12 : 8,
        stroke: new Stroke({
          color: "#fff",
          width: 1,
        }),
        fill: new Fill({
          color: iconColor,
        }),
      }),
      text: new Text({
        text: size == 1 ? "" : size.toString(),
        fill: new Fill({
          color: "#fff",
        }),
      }),
      zIndex: zIndex,
    });
  }

  lineStyle() {
    return new Style({
      stroke: new Stroke({ color: "#ff003380", width: 2 }),
    });
  }

  createLocationLayer() {
    this.locationSource = new VectorSource({});
    return new VectorLayer({ source: this.locationSource });
  }

  showLocation() {
    this.locationSource.clear(true);

    let locationFeature = new Feature({
      geometry: new Point(fromLonLat(this.location)),
    });
    locationFeature.setStyle(
      new Style({
        image: new Icon({
          crossOrigin: "anonymous",
          src: this.locationIcon,
          size: [62, 62],
        }),
      })
    );
    this.locationSource.addFeatures([locationFeature]);
  }

  createKmlLayer() {
    this.kmlSource = new VectorSource({});
    this.kmlLayer = new VectorLayer({ source: this.kmlSource });
    return this.kmlLayer;
  }

  addKmlSource(url, source) {
    var xhr = new XMLHttpRequest();
    var onError = () => {
      console.log(`Error loading map data from ${url}`);
    };
    xhr.onerror = onError;
    xhr.onload = () => {
      if (xhr.status == 200) {
        source.addFeatures(new KML().readFeatures(xhr.responseText, { featureProjection: projGet("EPSG:3857") }));
        this.centerMap();
      } else {
        onError();
      }
    };
    xhr.open("GET", url, true);
    xhr.send();
  }

  createPlotsLayer() {
    this.plotsSource = new VectorSource({});
    this.plotsLayer = new VectorLayer({ source: this.plotsSource });
    return this.plotsLayer;
  }

  updatePlots() {
    if (!this.hasTempContent) {
      if (this.map.getView().getZoom() > this.data.get("plots-kml-zoom-level")) {
        var url = this.data.get("plots-kml-path");
        if (url.includes("?")) {
          url += "&";
        } else {
          url += "?";
        }
        url += `map_bounding_box=${JSON.stringify(this.getBoundingBox())}`;
        fetch(url, { method: "GET" })
          .then((response) => response.text())
          .then((text) => {
            this.plotsSource.clear(true);
            this.plotsSource.addFeatures(new KML().readFeatures(text, { featureProjection: projGet("EPSG:3857") }));
          })
          .catch((error) => console.error("Error adding plots KML:", error));
      } else {
        this.plotsSource.clear(true);
      }
    }
  }

  showTempPlots(kml) {
    this.plotsSource.clear(true);
    this.plotsSource.addFeatures(new KML().readFeatures(kml, { featureProjection: projGet("EPSG:3857") }));
  }

  addFilterableProperties() {
    this.map.on("pointermove", (e) => {
      this.showHovers(e);
    });

    this.selectClick = new Select();
    this.map.addInteraction(this.selectClick);
    this.selectClick.on("select", (e) => {
      this.setStyleForSelected(e);
      // this.showPopupForKmlData(e);
    });

    // Triggers on each click on the map
    this.map.on("singleclick", (_e) => {});

    if (this.filterableController) {
      this.map.on("moveend", (_e) => {
        this.filterableController.setMapBoundingBox(this.getBoundingBox());
      });
    }

    if (this.data.has("toggle-plots-for-organization-id")) {
      this.selectClick.on("select", (e) => {
        this.addOrRemovePlotForOrganization(e);
      });
    }

    if (this.data.has("plots-kml-path")) {
      this.map.on("moveend", (_e) => {
        this.updatePlots();
      });
    }

    if (this.data.has("previews-path")) {
      // Big map with a filter on the side
      this.selectClick.on("select", (e) => {
        this.showPopupForSelectedFeatures(e);
      });
    } else {
      // Small map without a filter
      this.selectClick.on("select", (e) => {
        if (!!e.target.getFeatures().getArray()[0].getProperties().features) {
          document.location = e.target.getFeatures().getArray()[0].getProperties().features[0].getProperties().path;
        }
      });
    }
  }

  showPopupForSelectedFeatures(e) {
    var ids = [];
    for (var i = 0; i < e.target.getFeatures().getArray().length; i++) {
      if (e.target.getFeatures().getArray()[i].getProperties().features) {
        for (var j = 0; j < e.target.getFeatures().getArray()[i].getProperties().features.length; j++) {
          var featureProps = e.target.getFeatures().getArray()[i].getProperties().features[j].getProperties();
          ids.push(`${featureProps.object_class}_ids[]=${featureProps.id}`);
        }
      }
    }

    if (ids.length > 0) {
      fetch(this.data.get("previews-path") + "&" + ids.join("&"), { headers: { "Preview-Only": "true" } })
        .then((response) => response.text())
        .then((html) => {
          this.popupContentTarget.innerHTML = html;
          this.popupTarget.classList.remove("invisible");
        });
    } else {
      this.closePopup();
    }
  }

  showHovers(e) {
    if (!this.hoverOverlay) {
      this.hoverOverlay = new Overlay({
        element: this.hoverTarget,
        offset: [12, 12],
      });
      this.map.addOverlay(this.hoverOverlay);
    }

    const valuesToShow = [];
    this.map.forEachFeatureAtPixel(
      e.pixel,
      (feature, _layer) => {
        let features = feature.get("features");

        if (features && features.length > 0) {
          features.forEach((clusterFeature) => {
            var html = `<div>${clusterFeature.get("name")}</div>`;
            if (clusterFeature.get("roles").length > 0) {
              html += `<div class="small pl-2">${clusterFeature.get("roles")}</div>`;
            }
            valuesToShow.push(html);
          });
        }
        if (!!feature.get("description")) {
          var html = `<div class="font-italic">${feature.get("description")}</div>`;
          if (!!feature.get("roles")) {
            html += `<div class="small pl-2">${feature.get("roles")}</div>`;
          }
          valuesToShow.push(html);
        }
      },
      {
        layerFilter: (layer) => {
          return layer.type === new VectorLayer().type ? true : false;
        },
        hitTolerance: 0,
      }
    );

    if (!valuesToShow || valuesToShow.length === 0) {
      this.hoverTarget.classList.add("invisible");
    } else {
      this.hoverTarget.innerHTML = _.uniq(valuesToShow).join("");
      this.hoverTarget.classList.remove("invisible");
      this.hoverOverlay.setPosition(e.coordinate);
    }
  }

  setStyleForSelected(e) {
    e.selected.forEach((feature) => {
      // Should check for `feature.getProperties().geometry == LineString` here, but that does not seem to work
      if (typeof feature.getProperties().features != "undefined") {
        let firstFeature = feature.getProperties().features[0];
        if (firstFeature.getProperties().premium) {
          feature.setStyle(this.premiumIconStyle(firstFeature, 0, true));
        } else {
          feature.setStyle(this.pointIconStyle(feature.getProperties().features, 0, true));
        }
      }
    });

    e.deselected.forEach((feature) => {
      if (typeof feature.getProperties().features != "undefined") {
        feature.setStyle(null);
      }
    });
  }

  addOrRemovePlotForOrganization(e) {
    e.selected.forEach((feature) => {
      if (!!feature.values_.plot_id) {
        var json_body = {
          organization_id: this.data.get("toggle-plots-for-organization-id"),
          plot_id: feature.values_.plot_id,
        };
        fetch(this.data.get("toggle-plots-url"), {
          method: "POST",
          headers: fetchHeaders(),
          body: JSON.stringify(json_body),
        })
          .then((_response) => this.updatePlots())
          .catch((error) => console.error("Error updating plot for organization:", error));
      }
    });
  }

  // showPopupForKmlData(e) {
  //   e.selected.forEach((feature) => {
  //     for(var propertyName in feature.values_) {
  //       if (typeof feature.values_[propertyName] == "string") {
  //         console.log(feature.values_[propertyName]);
  //       }
  //     }
  //   });

  //   e.deselected.forEach((feature) => {
  //     debugger
  //   });
  // }

  setMapPosition() {
    if (this.data.has("initial-bounds")) {
      this.map.getView().fit(transformExtent(JSON.parse(this.data.get("initial-bounds")), "EPSG:4326", "EPSG:3857"));
      this.data.delete("initial-bounds");
      this.filterableController.setMapBoundingBox(this.getBoundingBox());
    }
    if (this.data.has("center")) {
      this.setCenter(this.data.get("center"));
    }
    if (this.data.has("zoom")) {
      this.setZoom(this.data.get("zoom"));
    }
  }

  centerMap() {
    if (this.data.get("zoom-to-fit") != "true") {
      return;
    }

    var extent = olExtent.createEmpty();
    olExtent.extend(extent, this.pointsSource.getExtent());
    olExtent.extend(extent, this.premiumSource.getExtent());
    olExtent.extend(extent, this.kmlSource.getExtent());

    if (!olExtent.isEmpty(extent)) {
      this.map.getView().fit(extent, { size: this.map.getSize(), maxZoom: this.data.get("zoom") || 9, padding: [40, 40, 40, 40] });
    }
  }

  setZoom(level) {
    this.map.getView().setZoom(level);
  }

  setCenter(json_coords) {
    this.map.getView().setCenter(fromLonLat(JSON.parse(json_coords)));
  }

  getBoundingBox() {
    return transformExtent(this.map.getView().calculateExtent(), "EPSG:3857", "EPSG:4326");
  }

  panMap(coordinates) {
    if (!this.location || !this.map) {
      return;
    }

    // Pan the map on user location updates
    var oldLocation = fromLonLat(this.location);
    var newLocation = fromLonLat(coordinates);
    var currentCenter = this.map.getView().getCenter();

    var newCenterX = currentCenter[0] - oldLocation[0] + newLocation[0];
    var newCenterY = currentCenter[1] - oldLocation[1] + newLocation[1];

    this.map.getView().setCenter([newCenterX, newCenterY]);
  }

  closePopup() {
    this.popupContentTarget.innerHTML = "";
    this.popupTarget.classList.add("invisible");

    if (!!this.selectClick) {
      this.selectClick.getFeatures().forEach((feature) => {
        feature.setStyle(null);
      });
      // Disabled to allow selecting KML plot
      // this.selectClick.getFeatures().clear();
    }

    !!this.hasTempContent && this.removeTempContent();
  }

  removeTempContent() {
    this.hasTempContent = false;
    this.setMainData(JSON.parse(this.data.get("data")));
    this.updatePlots();
  }

  get filterableController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-controller='filterable']"),
      "filterable"
    );
  }

  addEventListeners() {
    addEventListener("map:setZoom", this, false);
    addEventListener("map:setCenter", this, false);
  }

  removeEventListeners() {
    removeEventListener("map:setZoom", this, false);
    removeEventListener("map:setCenter", this, false);
  }

  handleEvent(e) {
    switch (e.type) {
      case "map:setZoom":
        this.setZoom(e.detail);
        break;
      case "map:setCenter":
        this.setCenter(e.detail);
        break;
    }
  }
}
