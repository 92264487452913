import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["postcode"];
  serviceURL = "/geocode?query=";

  searchKeyboard(event) {
    if (event.keyCode == 13) {
      // this.search();
      document.activeElement.blur();
    }
  }

  search() {
    let postcode = this.postcodeTarget.value;
    if (postcode == "") return;

    fetch(this.serviceURL + postcode)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "OK") {
          window.dispatchEvent(new CustomEvent("map:setZoom", { detail: 12 }));
          window.dispatchEvent(new CustomEvent("map:setCenter", { detail: JSON.stringify(json.coordinate) }));
        } else {
          alert(`Geen locatie gevonden voor "${postcode}".`);
        }
      })
      .catch((error) => {
        alert("Er is iets fout gegaan...");
      });
  }
}
