// Convert a select dropdown into a select2 element with dynamic lookup for results

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const elementId = $(this.element).data('typeaheadElementId') || "id"
    const elementText = $(this.element).data('typeaheadElementText') || "name"

    const element = this.element
    $(element)
      .select2({
        allowClear: true,
        ajax: {
          url: $(this.element).data('typeaheadUrl'),
          data: function (params) {
            var query = {
              query: params.term
            }

            // Query parameters will be ?query=[term]
            return query;
          },
          dataType: "json",
          delay: 250,
          processResults: function(data, page) {
            return {
              results: $.map( data, function(element, i) {
                return { id: element[elementId], text: element[elementText] }
              } )
            }
          }
        },
        minimumInputLength: 3,
        width: null
      })
      .on('select2:select', function() {
        let event = new Event('change', { bubbles: true })
        element.dispatchEvent(event)
      })
      .on('select2:unselect', function() {
        element.value = undefined

        let event = new Event('change', { bubbles: true })
        element.dispatchEvent(event)
      })

    if ($(element).prop("autofocus")) {
      $(element).select2('open')
    }
  }

  disconnect() {}
}
