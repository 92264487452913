import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    this.flatpickr = flatpickr(this.element, {
      altInput: true,
      altFormat: "d-m-Y"
    });
  }

  disconnect() {
    this.flatpickr.destroy();
  }
}
