// Store scroll position on expanding description and re-apply scroll position when collapsing

import { Controller } from "stimulus"

export default class extends Controller {
  counterElement = null;

  connect() {
    var node = document.createElement("span")
    node.className += 'input-counter'
    this.element.parentNode.insertBefore(node, this.element.nextSibling);
    this.counterElement = node;
    this.update()
    this.element.addEventListener("keyup", this.update.bind(this))
  }

  update() {
    this.counterElement.innerHTML = this.element.value.length
  }
}
