(function($) {

  // Options:
  // - url
  // - initialSelection
  // - resourceName
  // - inputName
  // - multiple
  // - sortable
  // - noMatchText
  // - allowCreateNew
  // - createNewText
  // - createNewOnPage
  // - minChars
  // - delay
  // - linkInserter
  // - linkInserterEditorClass
  $.fn.autocompleteSelectResources = function(options) {
    var self = this;
    var settings = $.extend({
      initialSelection: {},
      multiple: true,
      createNewOnPage: false,
      minChars: 2,
      delay: 400
    }, $.extend($(self).data(), options));

    self.timer = null;
    self.cache = {};
    self.xhr = null;

    var specialKeyCodes = [9,13,27,38,40];


    if(!APP.selections) {
      APP.selections = {}
    }
    if(!APP.drawSelection) {
      APP.drawSelection = {}
    }


    var selection = APP.selections[settings.resourceName] = settings.initialSelection;

    var suggestions = [];
    var selectedIndex = -1;
    var $input = self;
    var $form = $input.closest("form");

    // Remove these elements when already there which happens with turbolinks cached pages, resulting
    // in this being added twice.
    // TODO handle this with a 'unload' like function that can be called by turbolinks before cache event.
    $('.autocompletion-elements', self.parent()).remove();

    var $autocompletionElements = $("<div class='autocompletion-elements'></div>");
    var $suggestions = $("<ul class='autocomplete-suggestions'></ul>");
    if(settings.sortable) {
      var $selected = $("<ul class='selected sortable' data-controller='sortable'></ul>");
    }
    else {
      var $selected = $("<ul class='selected'></ul>");
    }
    $autocompletionElements.append($suggestions, $selected);

    $(self).after($autocompletionElements);

    if(settings.multiple === false) {
      self.parent().addClass("single");
    }

    // Made available through global APP so rjs response can call it:
    var drawResourceSelection = APP.drawSelection[settings.resourceName] = function() {
      $selected.empty();
      $.each(selection, function(k,v) {
        $a = $("<li><input type='hidden' value='"
               + v.id
               + "' name='"
               + settings.inputName
               + "'>"
               + (settings.sortable ? '<span class="handle"></span>' : '')
               + v.labelHtml
               + " <a href='#' class='remove'></a></li>"
        );
        if(settings.linkInserter) {
          var $linkInserter = $("<a href='" + v.gid + "' class='link-inserter'>insert link</a>");
          $linkInserter.click(function() {
            var selectionText = $('.' + settings.linkInserterEditorClass).froalaEditor('selection.text');
            // TODO Reorganize the labelHtml - see autocomplete selector json etc
            var labelText = $("<div/>").html(v.labelHtml).text();
            var linkText = (selectionText === "" ? labelText : selectionText);
            $('.' + settings.linkInserterEditorClass).froalaEditor('link.insert', false, linkText, {href: "#managed-by-system", title: labelText, "data-gid": v.gid });
            return false;
          });
          $a.append($linkInserter);
        }

        $(".remove", $a).click(function() {
          removeSelected(k);
          return false;
        });
        $selected.append($a);
      });
      if(Object.keys(selection).length === 0) {
        $selected.append("<input type='hidden' value='' name='" + settings.inputName + "'>");
      }
    }

    $input.on('keydown', function(e) {
      if(e.which === 9) { // Tab
        if($input.val() !== "") {
          selectNext();
          e.preventDefault();
        }
      }
      if(e.which === 13) { // Enter
        addSelected();
        clearList();
        e.preventDefault();
      }
      if(e.which === 27) { // Esc
        clearList();
        e.preventDefault();
      }
      if(e.which === 38) { // Arrow up
        selectPrevious();
        e.preventDefault();
      }
      if(e.which === 40) { // Arrow down
        selectNext();
        e.preventDefault();
      }
    })
    .keyup(function(x) {
      if(specialKeyCodes.indexOf(x.which) === -1) {
        clearTimeout(self.timer);
        self.timer = setTimeout(fetchSuggestions, settings.delay);
      }
    })
    .change(function() {
      //fetchSuggestions();
    });


    var fetchSuggestions = function() {
      if(self.xhr !== null && self.xhr.readyState !== 4) { self.xhr.abort(); }
      var query = $input.val();
      if(query.length >= settings.minChars) {
        if(query in self.cache) {
          suggestions = self.cache[query];
          applySuggestions();
          return;
        }
        self.xhr = $.ajax({
          method: "GET",
          url: settings.url,
          dataType: "json",
          data: {
            query: query,
          }
        }).done(function(data) {
          self.cache[query] = data;
          suggestions = data;
          applySuggestions();
        }).fail(function(jqXHR, status) {
          if(status !== "abort") {
            suggestions = [];
            applySuggestions();
            alert('Failed to find resource');
          }
        });
      }
      else {
        suggestions = [];
        applySuggestions();
      }
      return false;
    }

    var applySuggestions = function() {
      if(suggestions.length > 0) {
        selectedIndex = 0;
      }
      else {
        selectedIndex = -1;
      }
      drawList();
    }

    var selectNext = function() {
      if(selectedIndex === suggestions.length - 1) {
        selectedIndex = -1;
      }
      else {
        selectedIndex = (selectedIndex + 1)%(suggestions.length);
      }
      drawList();
    }

    var selectPrevious = function() {
      if(selectedIndex === 0) {
        selectedIndex = suggestions.length - 1;
      }
      else {
        selectedIndex -= 1;
      }
      drawList();
    }

    var clearList = function() {
      $input.val("");
      suggestions = [];
      drawList();
    }

    var drawList = function() {
      $suggestions.empty();
      var slis = [];
      $.each(suggestions, function(i, v) {
        var $sli = $('<li>' + v.labelHtml + '</li>');
        if(i === selectedIndex) {
          $sli.addClass('selected');
        }
        $sli.on('click', function(e) {
          selectedIndex = i;
          addSelected();
          clearList();
        });
        slis.push($sli);
      });
      if($input.val() !== "") {
        if(settings.allowCreateNew === true) {
          if(settings.createNewOnPage === true) {
            var $createNew = $('<li>' + settings.noMatchText + ' ' + settings.createNewText + '</li>');
          }
          else {
            var $createNew = $('<li>' + settings.noMatchText + ' ' + '<a href="' + settings.createNewPath + '">' + settings.createNewText + '</a></li>');
          }
          $createNew.on('click', function() {
            selectedIndex = -1;
            if(settings.createNewOnPage === true) {
              addSelected();
            }
            else {
              window.open(settings.createNewPath, '_blank');
              $input.focus();
            }
            clearList();
            return false;
          });
          if(selectedIndex === -1) {
            $createNew.addClass('selected');
          }

        }
        slis.push($createNew);
        $suggestions.append(slis);
      }
    }

    var addSelected = function() {
      if(selectedIndex === -1) {
        if(settings.createNewOnPage === true) {
          $.ajax({
            method: "GET",
            dataType: "script",
            url: settings.createNewPath,
            data: {
              // Send current value so object can be initialized with it
              query: $input.val()
            }
          }).fail(function() {
            alert("Error");
          });
        }
      }
      else {
        if(settings.multiple === false) {
          selection = {};
        }
        selection[suggestions[selectedIndex]['id']] = suggestions[selectedIndex];
        drawResourceSelection();
        $input.focus();
        submitForm();
      }
    }

    var removeSelected = function(id) {
      delete selection[id.toString()];

      drawResourceSelection();
      submitForm();
    }

    var submitForm = function() {
      if($form.hasClass("filterable-content")) {
        $form.trigger("change");
      }
    }

    // TODO add a destroy function to unregister this autocomplete stuff from
    // the element. See slick carousels unslick option.

    drawResourceSelection();
    return this;
  }
}(jQuery));
