import autosize from 'autosize'
window.autosize = autosize

document.addEventListener("turbolinks:load", () => {
  // Auto-size all textarea's
  autosize($('textarea'));
  $('a[data-toggle="tab"]').on('shown.bs.tab', function (_e) {
    autosize.update($('textarea'))
  })
  // After initial page load, textarea's no longer properly size. This 'fixes' it.
  // Behaviour difference can be seen between Cmd-Shift-R and Cmd-R reloads
  setTimeout(function() { autosize.update(document.querySelectorAll('textarea')); }, 50)

  // Make rows clickable
  $('table.tr-links > tbody > tr').each((_index, tr) => {
    const links = $(tr).find('a[href]')
    if (links.length > 0) {
      $(tr).on('click', function(event) {
        // Only execute if clicked on anything else in table row
        if ($.inArray(event.target.tagName.toLowerCase(), ['a', 'i', 'button', 'input', 'path']) < 0) {
          document.location = links[0].href;
        }
      });
      $(tr).addClass('clickable');
    }
  });
});

document.addEventListener("turbolinks:before-cache", () => {
  $('.select2-hidden-accessible').select2('destroy');
})

document.addEventListener("turbolinks:before-render", (event) => {
  if (typeof FontAwesome !== 'undefined') {
    FontAwesome.dom.i2svg({
      node: event.data.newBody
    });
  }
});
