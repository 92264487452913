APP.trackPage = function(url, title) {
  ga('send', { hitType: 'pageview', title: title, location: url });
}

document.addEventListener("turbolinks:load", function(){
  if(APP.google_analytics_enabled) {
    ga('set', 'anonymizeIp', true);
    ga('set', 'forceSSL', true);
    APP.trackPage(location.href, document.title);
  }
})
