// Convert a select dropdown into a select2 element

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    // Allow clearing the dropdown if there's an option with an empty value
    var allowClear = this.element.querySelectorAll("option:not([value]), option[value='']").length
    var minimumResultsForSearch = this.element.dataset["disableSearch"] == 'true' ? Infinity : 1
    var tokenSeparators = this.element.dataset["tokenSeparators"] || ""
    var tags = this.element.dataset["tags"] || false

    const element = this.element
    $(element)
      .select2({
        allowClear: allowClear,
        width: null,
        minimumResultsForSearch: minimumResultsForSearch,
        tags: tags,
        tokenSeparators: tokenSeparators.split('')
      })
      .on('select2:select', function() {
        // Bubble the select2 select event up to original select dropdown for proper 'onChange' execution
        let event = new Event('change', { bubbles: true })
        element.dispatchEvent(event)
      })
      .on('select2:unselecting', function() {
        // Cleanup any existing select2 dropdowns
        $(element).select2().select2('close');
      })
      .on('select2:unselect', function() {
        // Bubble the select2 unselect event up to original select dropdown for proper 'onChange' execution
        if (!element.multiple) {
          // FIXME: This clears all on a multiple select dropdown
          element.value = ""
        }

        let event = new Event('change', { bubbles: true })
        element.dispatchEvent(event)
      })

    if ($(element).prop("autofocus")) {
      $(element).select2('open')
    }
  }

  disconnect() {}
}
