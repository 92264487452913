import { Controller } from "stimulus"

export default class extends Controller {
  setCenterAndZoom(e) {
    e.preventDefault();

    window.dispatchEvent(new CustomEvent('map:setZoom', {detail: e.currentTarget.dataset.zoom}));
    window.dispatchEvent(new CustomEvent('map:setCenter', {detail: e.currentTarget.dataset.center}));

    this.element.querySelectorAll(".active").forEach((element) => { element.classList.remove('active') });
    e.currentTarget.parentElement.classList.add('active')
  }
}
