// Store scroll position on expanding description and re-apply scroll position when collapsing

import { Controller } from "stimulus"

export default class extends Controller {
  scrollPosition = null;

  more() {
    this.scrollPosition = $(window).scrollTop()
  }

  less() {
    if (!!this.scrollPosition) {
      $([document.documentElement, document.body]).animate({
        scrollTop: this.scrollPosition
      }, 400);
    }
  }
}
