import { Controller } from "stimulus";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default class extends Controller {
  connect() {
    this.textarea = this.element.querySelector("textarea");
    ClassicEditor.create(this.textarea, {
      removePlugins: ["ImageCaption"],
      image: {
        toolbar: ["imageTextAlternative", "|", "imageStyle:alignLeft", "imageStyle:full", "imageStyle:alignRight"],
        styles: ["full", "alignLeft", "alignRight"],
      },
      link: {
        // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
        decorators: {
          addTargetToExternalLinks: {
            mode: "automatic",
            callback: (url) => /^(https?:)?\/\//.test(url),
            attributes: {
              target: "_blank",
              rel: "noopener noreferrer nofollow",
            },
          },
        },
      },
      mediaEmbed: {
        previewsInData: true, // TODO conceptually not the best, but otherwise we need a oembed parser/provider
        removeProviders: ["vimeo"],
        extraProviders: [
          {
            name: "momento360",
            url: /https:\/\/momento360.com\/\e.+/,
            html: (match) => {
              const url = match[0];
              return "<iframe src='" + url + "' width='100%' height='350px' allowfullscreen='true'></iframe>";
            },
          },
          // Overwrite default vimeo provider to enable autoplay, loop and suppress Vimeo branding
          // https://github.com/ckeditor/ckeditor5-media-embed/blob/master/src/mediaembedediting.js
          {
            name: "vimeo_custom",
            url: [
              /^vimeo\.com\/(\d+)/,
              /^vimeo\.com\/[^/]+\/[^/]+\/video\/(\d+)/,
              /^vimeo\.com\/album\/[^/]+\/video\/(\d+)/,
              /^vimeo\.com\/channels\/[^/]+\/(\d+)/,
              /^vimeo\.com\/groups\/[^/]+\/videos\/(\d+)/,
              /^vimeo\.com\/ondemand\/[^/]+\/(\d+)/,
              /^player\.vimeo\.com\/video\/(\d+)/,
            ],
            html: (match) => {
              const url = match[0];
              const id = match[1];

              return (
                '<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">' +
                `<iframe src="https://player.vimeo.com/video/${id}?autoplay=1&loop=1&title=0&byline=0&portrait=0" ` +
                'style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;" ' +
                'frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>' +
                "</iframe>" +
                "</div>"
              );
            },
          },
        ],
      },
    }).then((editor) => {
      this.editor = editor;
      if (this.textarea.disabled) {
        this.editor.isReadOnly = true;
      }
    });
  }

  disconnect() {
    this.editor = null;
  }
}
