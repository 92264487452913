import { Controller } from "stimulus"

// TODO user /hier as location query and set device geolocation in cookie (only browser needs it)
// and can show 'HALLO DAAR'
// Store geoloaction in  APP.geolocation
// - Use that to draw 'my location' marker on the map
// - Use that to determine whether to say Hallo DAAR
export default class extends Controller {
  static targets = [
    "panel",
    "devicePositionSupported",
    "devicePositionSuccess",
    "geolocationExplanation",
    "geolocationFailed",
    "geolocationSuccess",
  ]

  initialize() {
    if (navigator.geolocation) {
      this.supported = true
    } else {
      this.supported = false
      // TODO add a target with that message and show it
      this.devicePositionSupportedTarget.innerHTML = "Sorry, your device does not support geolocation"
    }
  }

  togglePanel(event) {
    this.element.classList.toggle('expanded')
    event.preventDefault();
  }

  useDevicePosition(event) {
    navigator.geolocation.getCurrentPosition(this.goToDevicePosition, () => {
      this.failed()
    });
    event.preventDefault();
  }

  failed() {
    this.showFailed()
  }

  showFailed() {
    try {
      this.geolocationExplanationTarget.classList.remove('visible')
      this.geolocationFailedTarget.classList.add('visible')
    } catch(e) {
      alert('Het is niet gelukt om je locatie met ons te delen. Controleer je instellingen in probeer opnieuw.')
    }
  }

  goToDevicePosition(position) {
    window.location = "/" + [Math.round(1000 * position.coords.latitude) / 1000, Math.round(1000 * position.coords.longitude) / 1000].join(",") + "+100km" + APP.path;
  }
}
