import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'display', 'mapBoundingBox', 'filter', 'filterPanel', 'submit', 'productCategoryId',
                    'viaWebshop', 'deliveryZipCode', 'pagingBar', 'perPage', 'sortAttribute', 'sortOrder']

  connect() {}

  toggleFilterPanel() {
    this.filterPanelTarget.classList.toggle('small-screen-visible');
  }

  submitRemoteForm() {
    this.submitTarget.click()
  }

  setMapBoundingBox(val) {
    // TODO or use data.get/set
    if (this.displayTarget.value === "map") {
      // only when changed
      if (this.mapBoundingBoxTarget.value !== JSON.stringify(val)) {
        this.mapBoundingBoxTarget.value = JSON.stringify(val)
        this.submitRemoteForm()
      }
    }
    else {
      this.mapBoundingBoxTarget.value = ""
    }
  }

  remoteUpdateSuccess(resp, x) {
    let [data, status, xhr] = event.detail;
    window.APP[this.data.get('name') + "Map"].setMainData(data.content);
    this.setFilterContent(data.filter_content);
    this.setPagingBarContent(data.paging_bar_content);
    history.pushState({}, data.title,  data.url);
  }

  remoteUpdateFailed(resp) {
    let [data, status, xhr] = event.detail;
    console.log('Error fetching data')
  }

  setPagePath(path) {
    history.pushState({}, window.title, path)
  }

  setFilterContent(html) {
    this.filterTarget.innerHTML = html
  }

  setPagingBarContent(html) {
    this.pagingBarTarget.innerHTML = html
  }

  setPerPage(event) {
    this.perPageTarget.value = event.target.value
    this.submitRemoteForm()
  }

  setSortAttribute(event) {
    this.sortAttributeTarget.value = event.target.value
    this.submitRemoteForm()
  }

  setSortOrder(event) {
    this.sortOrderTarget.value = event.target.value
    this.submitRemoteForm()
  }

  changeDisplay(event) {
    var display = event.target.dataset['display']
    document.location = this.updateQueryStringParameter(document.location.href, 'display', display)
    event.preventDefault();
  }

  changeResource(event) {
    var target_resource = event.target.dataset['targetResource']
    var current_resource = event.target.dataset['currentResource']
    var path = event.target.dataset['path']
    if (path.indexOf('?') > 0) {
      path = path.substr(0, path.indexOf('?'))
    }
    var re = new RegExp(`${current_resource}_search`,"g");
    document.location = path + document.location.search.replace(re, `${target_resource}_search`)
    event.preventDefault();
  }

  updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }
    else {
      return uri + separator + key + "=" + value;
    }
  }

  deselectOtherProductCategoriesAndSubmit(event) {
    this.productCategoryIdTargets.forEach((checkbox) => {
      if (checkbox == event.currentTarget) return;
        checkbox.checked = false;
    })
    this.submitRemoteForm();
  }
}
