import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'submit', 'query']

  connect() {
    this.formTarget.onSubmit = this.onSubmit
  }

  onSubmit() {
    if (this.queryTarget.value == '') {
      event.preventDefault()
    }

    document.activeElement.blur();
  }
}
